export default {
  affirmGroupLabel: {
    id: 'payment_group_label_affirm',
    defaultMessage: 'Pay in installments with Affirm',
  },
  afterpayGroupLabel: {
    id: 'payment_group_label_afterpay',
    defaultMessage: 'Afterpay',
  },
  applePayGroupLabel: {
    id: 'payment_group_apple_pay',
    defaultMessage: 'Apple Pay',
  },
  cardGroupLabel: {
    id: 'payment_group_label_card',
    defaultMessage: 'Credit or debit card',
  },
  clearpayGroupLabel: {
    id: 'payment_group_label_clearpay',
    defaultMessage: 'Clearpay',
  },
  epsGroupLabel: {
    id: 'payment_group_label_eps',
    defaultMessage: 'EPS',
  },
  giropayGroupLabel: {
    id: 'payment_group_label_giropay',
    defaultMessage: 'Giropay',
  },
  iDealGroupLabel: {
    id: 'payment_group_label_ideal',
    defaultMessage: 'iDEAL',
  },
  klarnaPayLaterGroupLabel: {
    id: 'payment_group_label_klarna_pay_later',
    defaultMessage: 'Pay later with Klarna',
  },
  klarnaSliceItGroupLabel: {
    id: 'payment_group_label_klarna_slice_it',
    defaultMessage: 'Pay in installments',
  },
  konbiniGroupLabel: {
    id: 'payment_group_label_konbini',
    defaultMessage: 'Pay at convenience stores',
  },
  mbwayGroupLabel: {
    id: 'payment_group_mbway',
    defaultMessage: 'MBway',
  },
  oneyGroupLabel: {
    id: 'payment_group_label_oney',
    defaultMessage: 'Pay in 3 or 4 installments',
  },
  paypalGroupLabel: {
    id: 'payment_group_label_paypal',
    defaultMessage: 'PayPal',
  },
  processoutSandboxGroupLabel: {
    id: 'payment_group_label_processout_sandbox',
    defaultMessage: 'ProcessOut sandbox',
  },
  sofortGroupLabel: {
    id: 'payment_group_label_sofort',
    defaultMessage: 'Sofort',
  },
  trustlyGroupLabel: {
    id: 'payment_group_label_trustly',
    defaultMessage: 'Trustly',
  },
  oneyMethodLabel: {
    id: 'payment_method_label_oney',
    defaultMessage: 'Pay in {installmentCount} installments',
  },
  affirmMethodName: {
    id: 'payment_method_name_affirm',
    defaultMessage: 'Affirm',
  },
  afterpayMethodName: {
    id: 'payment_method_name_afterpay',
    defaultMessage: 'Afterpay',
  },
  applePayMethodName: {
    id: 'payment_method_name_apple_pay',
    defaultMessage: 'Apple Pay',
  },
  cardMethodName: {
    id: 'payment_method_name_card',
    defaultMessage: 'Credit or debit card',
  },
  clearpayMethodName: {
    id: 'payment_method_name_clearpay',
    defaultMessage: 'Clearpay',
  },
  epsMethodName: {
    id: 'payment_method_name_eps',
    defaultMessage: 'EPS',
  },
  giropayMethodName: {
    id: 'payment_method_name_giropay',
    defaultMessage: 'Giropay',
  },
  idealMethodName: {
    id: 'payment_method_name_ideal',
    defaultMessage: 'iDEAL',
  },
  klarnaPayLaterMethodName: {
    id: 'payment_method_name_klarna_pay_later',
    defaultMessage: 'Klarna (Pay later)',
  },
  klarnaSliceItMethodName: {
    id: 'payment_method_name_klarna_slice_it',
    defaultMessage: 'Klarna (Monthly financing)',
  },
  konbiniGenericMethodName: {
    id: 'payment_method_name_konbini_generic',
    defaultMessage: 'Konbini',
  },
  mbwayMethodName: {
    id: 'payment_method_name_mbway',
    defaultMessage: 'MBway',
  },
  paypalMethodName: {
    id: 'payment_method_name_paypal',
    defaultMessage: 'PayPal',
  },
  processoutSandboxMethodName: {
    id: 'payment_method_name_processout_sandbox',
    defaultMessage: 'ProcessOut sandbox',
  },
  oneyMethodName: {
    id: 'payment_method_name_oney',
    defaultMessage: 'Oney {installmentCount}x',
  },
  sofortMethodName: {
    id: 'payment_method_name_sofort',
    defaultMessage: 'Sofort',
  },
  trustlyMethodName: {
    id: 'payment_method_name_trustly',
    defaultMessage: 'Trustly',
  },
  applePayNetworkName: {
    id: 'payment_network_name_apple_pay',
    defaultMessage: 'Apple Pay',
  },
  cartesBancairesNetworkName: {
    id: 'payment_network_name_cartes_bancaires',
    defaultMessage: 'Cartes Bancaires',
  },
  clearpayNetworkName: {
    id: 'payment_network_name_clearpay',
    defaultMessage: 'Clearpay',
  },
  visaNetworkName: {
    id: 'payment_network_name_visa',
    defaultMessage: 'Visa',
  },
  maestroNetworkName: {
    id: 'payment_network_name_maestro',
    defaultMessage: 'Maestro',
  },
  mastercardNetworkName: {
    id: 'payment_network_name_mastercard',
    defaultMessage: 'Mastercard',
  },
  americanExpressNetworkName: {
    id: 'payment_network_name_american_express',
    defaultMessage: 'American Express',
  },
  discoverNetworkName: {
    id: 'payment_network_name_discover',
    defaultMessage: 'Discover',
  },
  bancontactNetworkName: {
    id: 'payment_network_name_bancontact',
    defaultMessage: 'Bancontact',
  },
  paypalNetworkName: {
    id: 'payment_network_name_paypal',
    defaultMessage: 'PayPal',
  },
  affirmNetworkName: {
    id: 'payment_network_name_affirm',
    defaultMessage: 'Affirm',
  },
  afterpayNetworkName: {
    id: 'payment_network_name_afterpay',
    defaultMessage: 'Afterpay',
  },
  epsNetworkName: {
    id: 'payment_network_name_eps',
    defaultMessage: 'EPS',
  },
  familymartNetworkName: {
    id: 'payment_network_name_familymart',
    defaultMessage: 'Family Mart',
  },
  giropayNetworkName: {
    id: 'payment_network_name_giropay',
    defaultMessage: 'Giropay',
  },
  idealNetworkName: {
    id: 'payment_network_name_ideal',
    defaultMessage: 'iDEAL',
  },
  jcbNetworkName: {
    id: 'payment_network_name_jcb',
    defaultMessage: 'JCB',
  },
  klarnaNetworkName: {
    id: 'payment_network_name_klarna',
    defaultMessage: 'Klarna',
  },
  lawsonNetworkName: {
    id: 'payment_network_name_lawson',
    defaultMessage: 'Lawson',
  },
  mbwayNetworkName: {
    id: 'payment_network_name_mbway',
    defaultMessage: 'MBway',
  },
  ministopNetworkName: {
    id: 'payment_network_name_ministop',
    defaultMessage: 'Mini Stop',
  },
  oneyNetworkName: {
    id: 'payment_network_name_oney',
    defaultMessage: 'Oney',
  },
  payeasyNetworkName: {
    id: 'payment_network_name_payeasy',
    defaultMessage: 'PayEasy',
  },
  processoutNetworkName: {
    id: 'payment_network_name_processout',
    defaultMessage: 'ProcessOut',
  },
  seicomartNetworkName: {
    id: 'payment_network_name_seicomart',
    defaultMessage: 'Seicomart',
  },
  sofortNetworkName: {
    id: 'payment_network_name_sofort',
    defaultMessage: 'Sofort',
  },
  trustlyNetworkName: {
    id: 'payment_network_name_trustly',
    defaultMessage: 'Trustly',
  },
  bodyRedirection: {
    id: 'payment_method_provider_redirection_html',
    defaultMessage:
      "You will be redirected to {provider, html}'s website to complete your payment.",
  },
  bodySeeYouSoon: {
    id: 'payment_method_body_see_you_soon',
    defaultMessage: 'See you soon!',
  },
  bodyPaypal: {
    id: 'payment_method_body_paypal',
    defaultMessage: 'Pay in 4 or directly with Paypal, free of charge.',
  },
  bodyKlarnaSliceIt: {
    id: 'payment_method_body_klarna_slice_it',
    defaultMessage: 'Klarna allows you to pay in 4 monthly installments!',
  },
  bodyClearPay: {
    id: 'payment_method_body_clearpay',
    defaultMessage:
      'ClearPay allows you to pay in 4 interest-free payments. No fees.',
  },
  affirmErrorDisabled: {
    id: 'affirm_error_disabled',
    defaultMessage: 'Installment payment is momentarily unavailable 😞',
  },
  affirmErrorCountryDisabled: {
    id: 'affirm_error_country_disabled',
    defaultMessage: 'Installment payment is momentarily unavailable 😞',
  },
  affirmErrorOnlyMerchantIncompatible: {
    id: 'affirm_error_only_merchant_incompatible',
    defaultMessage: 'This seller does not accept installment payments 😞',
  },
  affirmErrorOneMerchantIncompatible: {
    id: 'affirm_error_one_merchant_incompatible',
    defaultMessage:
      'One of the merchants does not accept installment payments 😞',
  },
  affirmErrorManyMerchantsIncompatible: {
    id: 'affirm_error_many_merchants_incompatible',
    defaultMessage: 'Some merchants do not accept installment payments 😞',
  },
  affirmErrorPriceIncompatible: {
    id: 'affirm_error_price_incompatible',
    defaultMessage:
      'Total price of your cart must be between $200 and $3500 for installment payment',
  },
  affirmErrorInsuranceIncompatible: {
    id: 'affirm_error_insurance_incompatible',
    defaultMessage: 'Insurance is not compatible with installment payments 😞',
  },
  afterpayErrorDisabled: {
    id: 'afterpay_error_disabled',
    defaultMessage: 'Afterpay is momentarily unavailable 😞',
  },
  afterpayPriceIncompatible: {
    id: 'afterpay_error_price_incompatible',
    defaultMessage:
      'Total price of your cart must be between $250 and $2500 for Afterpay',
  },
  applePayErrorPriceIncompatible: {
    id: 'apple_pay_error_price_incompatible',
    defaultMessage:
      'Total price of your cart must be less than $700 for Apple Pay',
  },
  clearpayPriceIncompatible: {
    id: 'clearpay_error_price_incompatible',
    defaultMessage:
      'Total price of your cart must be between $250 and $2500 for Clearpay',
  },
  clearpayErrorDisabled: {
    id: 'clearpay_error_disabled',
    defaultMessage: 'Clearpay is momentarily unavailable 😞',
  },
  sofortErrorPriceIncompatible: {
    id: 'sofort_error_price_incompatible',
    defaultMessage:
      'Total price of your cart must be less than $800 for Sofort',
  },
  paypalErrorDisabled: {
    id: 'paypal_error_disabled',
    defaultMessage: 'PayPal is momentarily unavailable 😞',
  },
  paypalErrorCountryDisabled: {
    id: 'paypal_error_country_disabled',
    defaultMessage: 'PayPal is momentarily unavailable 😞',
  },
  paypalErrorOnlyMerchantIncompatible: {
    id: 'paypal_error_only_merchant_incompatible',
    defaultMessage: 'This seller does not accept PayPal 😞',
  },
  paypalErrorOneMerchantIncompatible: {
    id: 'paypal_error_one_merchant_incompatible',
    defaultMessage: 'One of the merchants does not accept PayPal 😞',
  },
  paypalErrorManyMerchantsIncompatible: {
    id: 'paypal_error_many_merchants_incompatible',
    defaultMessage: 'Some merchants do not accept PayPal 😞',
  },
  paypalErrorPriceIncompatible: {
    id: 'paypal_error_price_incompatible',
    defaultMessage:
      'Total price of your cart must be higher than $300 for PayPal',
  },
  paypalErrorInsuranceIncompatible: {
    id: 'paypal_error_insurance_incompatible',
    defaultMessage: 'Insurance is not compatible with PayPal 😞',
  },
  mbwayErrorDisabled: {
    id: 'mbway_error_disabled',
    defaultMessage: 'MB WAY is momentarily unavailable 😞',
  },
  mbwayErrorCountryDisabled: {
    id: 'mbway_error_country_disabled',
    defaultMessage: 'MB WAY is momentarily unavailable 😞',
  },
  mbwayErrorOnlyMerchantIncompatible: {
    id: 'mbway_error_only_merchant_incompatible',
    defaultMessage: 'This seller does not accept MB WAY 😞',
  },
  mbwayErrorOneMerchantIncompatible: {
    id: 'mbway_error_one_merchant_incompatible',
    defaultMessage: 'One of the merchants does not accept MB WAY 😞',
  },
  mbwayErrorManyMerchantsIncompatible: {
    id: 'mbway_error_many_merchants_incompatible',
    defaultMessage: 'Some merchants do not accept MB WAY 😞',
  },
  mbwayErrorPriceIncompatible: {
    id: 'mbway_error_price_incompatible',
    defaultMessage:
      'Total price of your cart must be less than $750 for MB WAY',
  },
  mbwayErrorInsuranceIncompatible: {
    id: 'mbway_error_insurance_incompatible',
    defaultMessage: 'Insurance is not compatible with MB WAY 😞',
  },
  oneyErrorDisabled: {
    id: 'oney_error_disabled',
    defaultMessage: 'Installment payment is momentarily unavailable 😞',
  },
  oneyErrorCountryDisabled: {
    id: 'oney_error_country_disabled',
    defaultMessage: 'Installment payment is momentarily unavailable 😞',
  },
  oneyErrorOnlyMerchantIncompatible: {
    id: 'oney_error_only_merchant_incompatible',
    defaultMessage: 'This seller does not accept installment payments 😞',
  },
  oneyErrorOneMerchantIncompatible: {
    id: 'oney_error_one_merchant_incompatible',
    defaultMessage:
      'One of the merchants does not accept installment payments 😞',
  },
  oneyErrorManyMerchantsIncompatible: {
    id: 'oney_error_many_merchants_incompatible',
    defaultMessage: 'Some merchants do not accept installment payments 😞',
  },
  oneyErrorPriceIncompatible: {
    id: 'oney_error_price_incompatible',
    defaultMessage:
      'Total price of your cart must be between $200 and $2000 for installment payment',
  },
  oneyErrorInsuranceIncompatible: {
    id: 'oney_error_insurance_incompatible',
    defaultMessage: 'Insurance is not compatible with installment payments 😞',
  },
  klarnaErrorDisabled: {
    id: 'klarna_error_disabled',
    defaultMessage: 'Installment payment is momentarily unavailable 😞',
  },
  klarnaErrorCountryDisabled: {
    id: 'klarna_error_country_disabled',
    defaultMessage: 'Installment payment is momentarily unavailable 😞',
  },
  klarnaErrorOnlyMerchantIncompatible: {
    id: 'klarna_error_only_merchant_incompatible',
    defaultMessage: 'This seller does not accept installment payments 😞',
  },
  klarnaErrorOneMerchantIncompatible: {
    id: 'klarna_error_one_merchant_incompatible',
    defaultMessage:
      'One of the merchants does not accept installment payments 😞',
  },
  klarnaErrorManyMerchantsIncompatible: {
    id: 'klarna_error_many_merchants_incompatible',
    defaultMessage: 'Some merchants do not accept installment payments 😞',
  },
  klarnaErrorPriceIncompatible: {
    id: 'klarna_error_price_incompatible',
    defaultMessage:
      'Total price of your cart must be higher than $300 for installment payment',
  },
  klarnaErrorInsuranceIncompatible: {
    id: 'klarna_error_insurance_incompatible',
    defaultMessage: 'Insurance is not compatible with installment payments 😞',
  },
  konbiniErrorDisabled: {
    id: 'konbini_error_disabled',
    defaultMessage: 'Konbini payment is momentarily unavailable 😞',
  },
  konbiniErrorPriceIncompatible: {
    id: 'konbini_error_price_incompatible',
    defaultMessage:
      'Total price of your cart must be between 200 and 300000YEN for Konbini payments',
  },
  oneyLegalNotice: {
    id: 'oney_legal_notice',
    defaultMessage: `Financing offer with compulsory contribution, reserved for individuals and valid for any purchase from 200.00 € to 2000.00 €. Subject to acceptance by Oney Bank, you have 14 days to renounce your credit. APR: {rate3x, html} for the 3x and {rate4x, html} for the 4x.
      Oney Bank - SA with a capital of € 50,741,215 - 40 avenue de Flandre 59170 Croix - 546 380 197 RCS Lille Métropole - Orias number 07 023 261 {link, html} , {link2, html}, {link3, html}.`,
  },
  oneyLegalNoticeLink: {
    id: 'oney_legal_notice_link',
    defaultMessage: 'www.orias.fr',
  },
  oneyLegalNoticeLink2: {
    id: 'oney_legal_notice_link_2',
    defaultMessage: 'www.orias.fr/welcome',
  },
  oneyLegalNoticeLink3: {
    id: 'oney_legal_notice_link_3',
    defaultMessage: 'www.oney.fr',
  },
}
