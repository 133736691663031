// import get from 'lodash/get'
// import { isEmpty } from '@backmarket/utils/object/isEmpty'

// import { sortByProperty } from '@backmarket/utils/collection/sortByProperty'
// import { fetchPaymentMethods as fetchPaymentMethodsRequest } from '@http/endpoints'
// import { getPlugin as getTranslationPlugin } from '@i18n/utils'

import {
  PaymentGroup,
  type PaymentMethod,
  PaymentMethodCode,
  PaymentNetwork,
} from '@backmarket/http-api/src/api-specs-payment/payment'
import { type I18nValues } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import {
  PAYMENT_ICON_PATHS,
  type PaymentIconPath,
  type PaymentMethodIconPath,
} from '../config/constants'
import {
  PAYMENT_GROUP_CONFIGS,
  PAYMENT_GROUP_DYNAMIC_CONFIGS,
  PAYMENT_METHOD_CONFIGS,
  PAYMENT_NETWORK_CONFIGS,
} from '../config/methods'
import type {
  PaymentGroupDynamicHardcodedConfigOptions,
  PaymentGroupHardcodedConfig,
  PaymentMethodHardcodedConfig,
  PaymentNetworkHardcodedConfig,
} from '../config/methods.type'

export function isPaymentMethodEnabled(paymentMethod: PaymentMethod) {
  return paymentMethod.enabled !== false
}

// /**
//  * @param {import('@payment').PaymentMethod} paymentMethod - Payment method.
//  * @returns {boolean} `true` if the payment method is debit/credit card, `false` otherwise.
//  */
// export function isCreditCard(paymentMethod) {
//   return paymentMethod.group === PAYMENT_GROUPS.CARD
// }

function paymentMethodConfigFallback(paymentMethodCode: PaymentMethodCode) {
  const name = {
    id: `Missing payment method config for "${paymentMethodCode}"`,
    defaultMessage: paymentMethodCode.toUpperCase(),
  }

  return { name, label: name }
}

export function paymentMethodConfig(
  paymentMethodCode: PaymentMethodCode,
): PaymentMethodHardcodedConfig {
  return (
    PAYMENT_METHOD_CONFIGS[paymentMethodCode] ||
    paymentMethodConfigFallback(paymentMethodCode)
  )
}

export function paymentMethodName(paymentMethodCode: PaymentMethodCode) {
  const i18n = useI18n()
  const { name, ...config } = paymentMethodConfig(paymentMethodCode)

  return i18n(name, config as unknown as I18nValues)
}

export function paymentMethodLabel(paymentMethodCode: PaymentMethodCode) {
  const i18n = useI18n()
  const { label, ...config } = paymentMethodConfig(paymentMethodCode)

  return label ? i18n(label, config as unknown as I18nValues) : ''
}

export function paymentMethodIcon(
  paymentMethodCode: PaymentMethodCode,
): PaymentMethodIconPath | null {
  // FIXME: We should not need to check if the payment method code is valid here.
  return Object.values(PaymentMethodCode).includes(paymentMethodCode)
    ? `${PAYMENT_ICON_PATHS.METHODS}/${paymentMethodCode}.svg`
    : null
}

function paymentGroupConfigFallback(paymentGroupId: PaymentGroup) {
  const providerName = paymentGroupId.toUpperCase()

  return {
    hasCustomIcon: false,
    label: {
      id: `Missing payment group config for "${paymentGroupId}"`,
      defaultMessage: providerName,
    },
    providerName,
    messages: [],
  }
}

export function paymentGroupConfig(
  paymentGroup: PaymentGroup,
  options: PaymentGroupDynamicHardcodedConfigOptions = {
    methods: [],
  },
): PaymentGroupHardcodedConfig {
  return (
    PAYMENT_GROUP_DYNAMIC_CONFIGS[paymentGroup]?.(options) ||
    PAYMENT_GROUP_CONFIGS[paymentGroup] ||
    paymentGroupConfigFallback(paymentGroup)
  )
}

export function paymentGroupLabel(paymentGroup: PaymentGroup) {
  const i18n = useI18n()

  return i18n(paymentGroupConfig(paymentGroup).label)
}

export function paymentGroupIcon(
  paymentGroup: PaymentGroup,
): PaymentIconPath | null {
  if (!Object.values(PaymentGroup).includes(paymentGroup)) {
    return null
  }

  return paymentGroupConfig(paymentGroup).hasCustomIcon
    ? `${PAYMENT_ICON_PATHS.GROUPS}/${paymentGroup}.svg`
    : null
}

// /**
//  * @param {string} paymentGroupId
//  * @param {string} error
//  * @returns {string|null} The Payment Group's errors message, if any.
//  */
// export function paymentMethodError(paymentGroupId, error) {
//   return get(paymentGroupConfig(paymentGroupId), `errorMessages.${error}`, null)
// }

function paymentNetworkConfigFallback(paymentNetwork: PaymentNetwork) {
  const providerName = paymentNetwork.toUpperCase()

  return {
    name: {
      id: `Missing payment network config for "${paymentNetwork}"`,
      defaultMessage: providerName,
    },
    icon: null,
  }
}

export function paymentNetworkConfig(
  paymentNetwork: PaymentNetwork,
): PaymentNetworkHardcodedConfig {
  return (
    PAYMENT_NETWORK_CONFIGS[paymentNetwork] ||
    paymentNetworkConfigFallback(paymentNetwork)
  )
}

// /**
//  * @param {string} paymentNetworkId
//  * @returns {string} The Payment Network's label
//  */
// export function paymentNetworkLabel(paymentNetworkId) {
//   const $t = getTranslationPlugin()

//   return $t(paymentNetworkConfig(paymentNetworkId).name)
// }

export function paymentNetworkIcon(
  paymentNetwork: PaymentNetwork,
): PaymentMethodIconPath | PaymentIconPath | null {
  if (!Object.values(PaymentNetwork).includes(paymentNetwork)) {
    return null
  }

  // Re-use payment method icons when a method with the same id exists. This is
  // the case for most payment networks, except: Card ones, `klarna` & `oney`.
  const paymentMethodCode = Object.values(PaymentMethodCode).find(
    (code) => (code as string) === paymentNetwork,
  )

  return paymentMethodCode
    ? `${PAYMENT_ICON_PATHS.METHODS}/${paymentMethodCode}.svg`
    : `${PAYMENT_ICON_PATHS.NETWORKS}/${paymentNetwork}.svg`
}

// /**
//  * @param {import('../config/methods').PaymentGroupConfig[]} paymentGroups
//  * @returns {import('../config/methods').PaymentGroupConfig[]} `paymentGroups`,
//  * sorted by order of declaration in `PAYMENT_GROUP_CONFIGS`.
//  */
// export function sortedPaymentGroups(paymentGroups) {
//   return sortByProperty(paymentGroups, 'id', Object.keys(PAYMENT_GROUP_CONFIGS))
// }

// /**
//  * @param {import('vuex').ActionContext<State, Object>} context The Vuex context object.
//  * @param {Object} [options] - An object containing optional parameters.
//  * @param {Array} [options.listings] - An array of listing IDs.
//  * @param {number} [options.bagPrice] - The price of a bag.
//  * @param {boolean} [options.hasInsurance] - A flag indicating whether insurance is included.
//  * @return {Promise<import('@payment').PaymentMethod[]>} The list of payment methods.
//  */
// export async function fetchPaymentMethods(context, options = {}) {
//   const { dispatch, rootGetters } = context
//   const { listings, bagPrice, hasInsurance } = options

//   const { payload } = await dispatch(
//     'http/request',
//     {
//       request: fetchPaymentMethodsRequest,
//       queryParams: {
//         country_code: rootGetters['config/country'],
//         listings: listings?.join(','),
//         bag_price: bagPrice,
//         insurance_subscription: hasInsurance,
//       },
//     },
//     { root: true },
//   )

//   return payload.results
// }

// /**
//  * Sort the payment networks by putting the preferred ones first.
//  * @param {string[]} networksIds
//  * @param {string[]} preferredNetworksIds
//  */
// export function sortPaymentNetworkByPreference(
//   networksIds,
//   preferredNetworksIds = null,
// ) {
//   if (!preferredNetworksIds) {
//     return networksIds
//   }

//   return [...(networksIds || [])].sort((brandA) =>
//     preferredNetworksIds.includes(brandA) ? -1 : 1,
//   )
// }
